<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="userSiteForm.name"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userSiteForm.email"
                type="email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Email"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="passwordRequired"
          md="6"
        >
          <!-- Name -->
          <validation-provider

            #default="validationContext"
            name="Password"
            rules="required"
          >
            <b-form-group
              label="Password"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="userSiteForm.password"
                type="password"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Password"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="passwordRequired"
          md="6"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Password Confirmation"
            rules="required"
          >
            <b-form-group
              label="Password Confirmation"
              label-for="password_confirmation"
            >
              <b-form-input
                id="password_confirmation"
                v-model="userSiteForm.password_confirmation"
                type="password"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Password Confirmation"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col
          v-if="isActivation"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Active"
          >

            <b-form-group
              label="Activation "
              label-for="Activation"
            >
              <b-form-select
                id="office_id"
                v-model="userSiteForm.active"
                :options="optionsActive"
                trim
                placeholder="Is Active"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>

        <b-col
          md="6"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            name="office_id"
          >

            <b-form-group
              label="office "
              label-for="office"
            >
              <b-form-select
                id="office_id"
                v-model="userSiteForm.office_id"
                :options="optionsOffice"
                trim
                placeholder="Office"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="role"
          >

            <b-form-group
              label="role "
              label-for="role"
            >
              <b-form-select
                id="role"
                v-model="userSiteForm.role"
                :options="optionsRoles"
                trim
                placeholder="role"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>

        <!-- required for engineer  -->
        <b-col
          v-if="isNotesSelected"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Joined At"
          >

            <b-form-group
              label="Joined At "
              label-for="Joined At"
            >
              <b-form-input
                id="joined_at"
                v-model="userSiteForm.joined_at"
                trim
                type="date"
                placeholder="Joined At"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>

        <b-col
          v-if="isNotesSelected"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Department"
          >

            <b-form-group
              label="Department "
              label-for="Department"
            >
              <b-form-select
                id="office_id"
                v-model="userSiteForm.department_id"
                :options="optionsDepertment"
                trim
                placeholder="Department"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>

        <b-col
          v-if="isNotesSelected"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Job Description"
          >

            <b-form-group
              label="Job Description "
              label-for="Job Description"
            >
              <b-form-input
                id="job_description"
                v-model="userSiteForm.job_description"
                trim
                placeholder="Job Description"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>

        <b-col
          v-if="isNotesSelected"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Status"
          >

            <b-form-group
              label="Status "
              label-for="Status"
            >
              <b-form-checkbox-group
                id="status_id"
                v-model="userSiteForm.status_id"
                class="d-flex flex-column"
                :options="optionsStatusId"
                trim
                placeholder="Status"
                :state="getValidationState(validationContext)"
                multiple
              />
              <!-- <div class="bg-black">
                Selected: <strong>{{ userSiteForm.status_id }}</strong>
              </div> -->

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >

          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addUserSite()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addUserSite()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      allSelected: [],
      users: [],
      loader: false,
      validationErrors: {},
      passwordRequired: true,
      isActivation: false,
      errors: {},
      optionsRoles: [],
      optionsOffice: [],
      optionsStatusId: [],
      optionsDepertment: [],
      optionsActive: [
        {
          value: '0',
          text: 'Not Active',
        },
        {
          value: '1',
          text: 'Active',
        },
      ],

    }
  },

  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const userSiteForm = ref({
      name: '',
      email: '',
      role: '',
      department_id: [],
      joined_at: '',
      job_description: '',
      office_id: [],
      active: [],
      status_id: [],
    })
    return {
      getValidationState,
      userSiteForm,
    }
  },
  computed: {
    isNotesSelected() {
      return this.userSiteForm.role === 'engineer'
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.passwordRequired = false
      this.isActivation = true
    }
    this.getRole()
    this.getOffice()
    this.getEngineerStatus()
    this.getDepartment()
    this.showUserSite()
  },
  methods: {

    getRole() {
      this.loadtable = true
      axios.get('register/site/roles').then(res => {
        this.optionsRoles = decryptData(res.data.payload).data.roles.map(item => ({
          value: item.name,
          text: item.name,
        }))
      }).finally(() => {
        this.loadtable = false
      })
    },

    getOffice() {
      this.loadtable = true
      axios.get('office').then(res => {
        this.optionsOffice = decryptData(res.data.payload).data.offices.map(item => ({
          value: item.id,
          text: item.name,
        }))
      }).finally(() => {
        this.loadtable = false
      })
    },
    getDepartment() {
      this.loadtable = true
      axios.get('departments').then(res => {
        this.optionsDepertment = decryptData(res.data.payload).data.departments.map(item => ({
          value: item.id,
          text: item.title,
        }))
      }).finally(() => {
        this.loadtable = false
      })
    },
    getEngineerStatus() {
      this.loadtable = true
      axios.get('engineer-status').then(res => {
        this.optionsStatusId = decryptData(res.data.payload).data.status.map(item => ({
          value: item.id,
          text: item.status,
        }))
      }).finally(() => {
        this.loadtable = false
      })
    },

    async showUserSite() {
      if (this.$route.params.id) {
        axios.get(`/register/site/user/${this.$route.params.id}`).then(res => {
          const userData = decryptData(res.data.payload).data.user
          this.userSiteForm.name = userData.name
          this.userSiteForm.email = userData.email
          this.userSiteForm.role = userData.role
          this.userSiteForm.department_id = userData.department_id
          this.userSiteForm.joined_at = userData.joined_at
          this.userSiteForm.job_description = userData.job_description
          this.userSiteForm.office_id = userData.office_id
          this.userSiteForm.active = userData.active
          this.userSiteForm.status_id = userData.status.map(item => item.id)
        })
      } else {
        return false
      }
      return true
    },

    addUserSite() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        formData.append('_method', 'PUT')

        Object.keys(this.userSiteForm).forEach(key => {
          if (Array.isArray(this.userSiteForm[key])) {
            this.userSiteForm[key].forEach(item => {
              formData.append(`${key}[]`, item)
            })
          } else {
            formData.append(key, this.userSiteForm[key])
          }
        })

        axios.post(`register/site/user/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'user-site' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.userSiteForm) {
          if (Array.isArray(this.userSiteForm[key])) {
            this.userSiteForm[key].forEach(item => {
              formData.append(`${key}[]`, item)
            })
          } else {
            formData.append(key, this.userSiteForm[key])
          }
        }

        axios.post('register/site', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'user-site' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
